import request from "@/utils/axios";
// 注：上传文件时，把 params 改成 data
// 用户管理-新增用户
export function addUser(params) {
  return request({
    url: "/api/user/register",
    method: "post",
    params,
  });
}
// 用户管理-用户列表
export function getuserlist(params) {
  return request({
    url: "/api/user/index",
    method: "get",
    params,
  });
}
// 用户管理-删除用户
export function deleteUser(params) {
  return request({
    url: "/api/deleteUser",
    method: "get",
    params,
  });
}
// 修改用户信息
export function editlist(params) {
  return request({
    url: "/api/video/addViewCount",
    method: "post",
    params,
  });
}

// 查询视频列表
export function VideoList(params) {
  return request({
    url: "/api/video/typeIndex",
    method: "get",
    params,
  });
}

// 查询用户视频权限
export function TypeVideo(params) {
  return request({
    url: "api/video/typeList",
    method: "get",
    params,
  });
}
// typeList
