<template>
  <div>
    <el-dialog title="编辑用户" :show-close="false">
      <el-form slot="footer">

        <el-form-item label="账号" :label-width="formLabelWidth">
          <el-input v-model="FormVal.phone" autocomplete="off" :disabled="true" style="width:400px"></el-input>
        </el-form-item>
        <el-form-item label="学生姓名" :label-width="formLabelWidth">
          <el-input v-model="FormVal.username" autocomplete="off" style="width:400px"></el-input>
        </el-form-item>
        <el-form-item label="增加次数" :label-width="formLabelWidth">
          <el-input v-model="Total_number" autocomplete="off" style="width:400px"></el-input>
        </el-form-item>

        <el-form-item label="视频权限" class="video1">
          <el-select v-model="FormVal.type_ids" multiple filterable allow-create default-first-option placeholder="请选择">
            <el-option v-for="item in label" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="Closet()">取 消</el-button>
        <el-button type="primary" @click="handlesubmit()">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { editlist, TypeVideo } from "@/api/user.js";
export default {
  props: {
    FormVal: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      type_ids: [{ id: 2 }, { id: 4 }],
      Total_number: 0,
      loading: false,
      label: "",
      formLabelWidth: "80px",
    };
  },
  mounted() {
    this.HandleData();

  },
  methods: {

    // remoteMethod(query) {
    //   if (query !== '') {
    //     this.loading = true;
    //     setTimeout(() => {
    //       this.loading = false;
    //       this.options = this.label.filter(item => { 
    //         return item.name.toLowerCase()
    //           .indexOf(query.toLowerCase()) > -1;
    //       });
    //     }, 200);
    //   } else {
    //     this.options = [];
    //   }
    // },

    HandleData() {
      TypeVideo().then(res => {
        if (res.code == 200) {
          this.label = res.data;
        }
      });
    },
    Closet() {
      this.$emit("Closet", 1);
    },

    handlesubmit() {
      let { union_id, watch_count, username } = this.FormVal;
      const str = this.FormVal.type_ids.join();
      editlist({ union_id: union_id, type_ids: str, count: this.Total_number, username, username }).then(
        (res) => {
          if (res.code == 200) {
            this.Closet();
            this.$message({
              type: "success",
              message: "修改角色成功",
            });
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.video1 .el-select {
  width: 420px;
}

.el-dialog__wrapper {
  display: block !important;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

::v-deep .el-form-item__content {
  text-align: left;
}
</style>