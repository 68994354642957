<template>
    <div>
        <div class="maxwidth">
            <div class="flex-sb">
                <div class="title">学生列表</div>
                <div class="Select">
                    <el-input v-model="SearchVal" placeholder="请输入学生姓名进行查询"></el-input>
                    <div class="div">
                        <el-button type="primary" @click="Search()">搜索</el-button>
                        <el-button type="success" @click="Reset()">重置</el-button>
                    </div>

                </div>
            </div>

            <el-table :data="tableData" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }">
                <el-table-column prop="username" label="学生姓名"> </el-table-column>
                <el-table-column prop="phone" label="手机号"> </el-table-column>
                <el-table-column prop="watch_count" label="观看次数"> </el-table-column>
                <el-table-column prop="created_at" label="注册时间"> </el-table-column>
                <el-table-column prop="address" label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleid(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="width30"></div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :total="total"
                :page-size="10">
            </el-pagination>
        </div>
        <Popup :FormVal="form" @Closet="Closet" v-if="dialogFormVisible"></Popup>
    </div>
</template>

<script>
import Popup from "../components/Popup.vue";
import { getuserlist } from "@/api/user"
export default {
    components: {
        Popup,
    },
    mounted() {
        this.HandleData()
    },
    data() {
        return {
            SearchVal:"",
            form: {
                phone: "",
                num: "",
                VideoDesc: [],
            },
            dialogFormVisible: false,
            tableData: [],
            total: 10,
        }
    },
    methods: {
        Search(){
            getuserlist({username:this.SearchVal}).then(res => {
                console.log(res);
                this.tableData = res.data.data;
                this.total = res.data.total;
            }); 
        },

        Reset(){
            this.SearchVal = ""
            this.HandleData();
        },

        handleSizeChange(val) {
            console.log(val);
        },
        handleCurrentChange(val) {
            getuserlist({ page: val }).then(res => {
                this.tableData = res.data.data;
            });
        },
        HandleData() {
            getuserlist().then(res => {
                console.log(res);
                this.tableData = res.data.data;
                this.total = res.data.total;
            });
        },

        handleid(val) {
            console.log(val, 22)
            this.dialogFormVisible = !this.dialogFormVisible;
            this.form = val
        },
        Closet(val) {
            if (val) {
                this.dialogFormVisible = !this.dialogFormVisible;
            }
        },
    },
}
</script>

<style scoped lang="scss">
.maxwidth {
    width: 1000px;
    margin: 50px auto;

    .flex-sb {
        display: flex;
        justify-content: space-between;

        .Select {
            display: flex;
            width: 380px;
            .el-input{
                width: 220px;
                margin-right: 10px;
            }
        }
    }
}

.title {
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: bold;
}

.width30 {
    width: 100%;
    height: 30px;
}
</style>